$primary_color: #04ABFB;
$light_color: #04ABFB;
$dark_color: #03426E;

.background-image {
  position: relative;
  width: 100%;
  height: 300px;
  /* Adjust the height as needed */
  display: flex;
  align-items: center;
  /* Center the content vertically */

  /* Add this for the dimming effect */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .25);
    /* Adjust the alpha value for the desired dimness */
  }

  /* Add more styles if needed */
}

.nav-bar {
  background-color: $light_color;
}

.google-drive-iframe {
  @media only screen and (max-width: 450px) {
    width: 100%;
    /* 100% / 0.75 to compensate for the 75% scaling */
    height: 75vh;
    /* 100% / 0.75 to compensate for the 75% scaling */
    border: 0;
    transform: scale(1.75);
    transform-origin: 0 0;
    padding-bottom: 30vh;
  }
  width: 100%;
  height: 100vh;
  border: 0;
}